import { FC, ReactElement } from 'react';

import { Card } from '../../../components';
import { OccupationFilterValues } from '../../../models/OccupationOverview/OccupationOverview';
import { SearchableOption } from '../../../types';
import { OccupationSearchForm } from '../..';

import './OccupationFilters.scss';

interface OccupationFiltersProps {
    isLoading: boolean;
    suggestionsIsLoading: boolean;
    activeFilterValues: OccupationFilterValues;
    occupationSuggestions: SearchableOption[];
    onSearchChange: (value: string) => void;
    onSearchSubmit: (value: string) => void;
    className?: string;
}

const OccupationFilters: FC<OccupationFiltersProps> = ({
    isLoading,
    suggestionsIsLoading,
    activeFilterValues,
    occupationSuggestions,
    onSearchChange,
    onSearchSubmit,
    className = '',
}): ReactElement => (
    <header className={`occupation-filters ${className}`}>
        <Card className="occupation-filters__card">
            <OccupationSearchForm
                isLoading={isLoading}
                suggestionsIsLoading={suggestionsIsLoading}
                value={activeFilterValues.searchQuery}
                occupationSuggestions={occupationSuggestions}
                onSearchChange={onSearchChange}
                onSubmit={onSearchSubmit}
                className="occupation-filters__search-form"
            />
        </Card>
    </header>
);

export default OccupationFilters;
