import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { InternalOccupationFilterValues, InternalOccupationOverview, InternalOccupationOverviewResource } from './InternalOccupationOverview';
import { transformToInternalOccupationOverview } from './InternalOccupationOverviewTransformers';

export const getInternalOccupationOverviewApiCall = async (
    candidateUuid: string,
    organisationUuid: string,
    skills: string[],
    competencies: string[],
    activeFilterValues: InternalOccupationFilterValues,
): Promise<FetchResult<InternalOccupationOverview, string>> => {
    try {
        const response = await lloFetch(`/api/internaloccupations/${organisationUuid}/matching?pageNumber=${activeFilterValues.pageNumber}&pageSize=${activeFilterValues.pageSize}`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify({
                search: activeFilterValues.searchQuery,
                skillsToMatch: skills,
                competenciesToMatch: competencies,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const occupationOverviewResponse: InternalOccupationOverviewResource = await response.json();

        const occupationOverview = transformToInternalOccupationOverview(occupationOverviewResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: occupationOverview,
        };
    } catch (error) {
        console.error('[getInternalOccupationOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
