import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { InternalOccupationDetailHeader } from '../../../containers';
import { Candidate } from '../../../models/Candidate/Candidate';
import { InternalOccupationDetail } from '../../../models/InternalOccupationDetail/InternalOccupationDetail';
import { fetchInternalOccupationDetail } from '../../../redux/internalOccupationDetail/internalOccupationDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { CompetencyLevel } from '../../../types';

interface ConnectedInternalOccupationDetailHeaderProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onInternalOccupationDetailResponse: (occupationDetail: InternalOccupationDetail) => void;
    className?: string;
}

const ConnectedInternalOccupationDetailHeader: FC<ConnectedInternalOccupationDetailHeaderProps> = ({
    onCandidateResponse,
    onInternalOccupationDetailResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { occupationUuid } = useParams();

    const user = useTypedSelector(state => state.userReducer.user);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const competencies = useTypedSelector(state => state.skillsPassportReducer.competencies);
    const skills = useTypedSelector(state => state.skillsPassportReducer.skills);

    const occupationIsLoading = useTypedSelector(state => state.internalOccupationDetailReducer.isLoading);
    const occupationError = useTypedSelector(state => state.internalOccupationDetailReducer.error);
    const occupation = useTypedSelector(state => state.internalOccupationDetailReducer.internalOccupation);

    const pageIsLoading = [
        candidateIsLoading,
        occupationIsLoading,
        skillsPassportIsLoading,
    ].some(Boolean);

    useEffect((): void => {
        if (occupation) onInternalOccupationDetailResponse(occupation);
    }, [occupation]);

    useEffect((): void => {
        if (!candidate) return;

        onCandidateResponse(candidate);

        if (user && candidate.uuid && occupationUuid) {
            const highCompetencies = competencies
                ? competencies[CompetencyLevel.high].map(competency => competency.label)
                : [];

            const regularCompetencies = competencies
                ? competencies[CompetencyLevel.regular].map(competency => competency.label)
                : [];

            const skillsToMatch = skills.map(skill => skill.name);

            dispatch(fetchInternalOccupationDetail({
                occupationUuid,
                organisationUuid: user.organisation.id,
                candidateUuid: candidate.uuid,
                skillsToMatch,
                competenciesToMatch: [...highCompetencies, ...regularCompetencies],
            }));
        }
    }, [candidate, competencies]);

    return (
        <InternalOccupationDetailHeader
            pageIsLoading={pageIsLoading}
            occupationError={occupationError}
            occupation={occupation}
            className={className}
        />
    );
};

export default ConnectedInternalOccupationDetailHeader;
