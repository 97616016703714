import { FC, ReactElement, useEffect } from 'react';

import { OccupationOverview } from '../../../containers';
import { scrollToTop } from '../../../helpers/scroll';
import { setActiveFilterValues } from '../../../redux/internalOccupationOverview/internalOccupationOverview';
import { fetchInternalOccupationOverview, setInternalOccupationOverviewIsLoading } from '../../../redux/internalOccupationOverview/internalOccupationOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { CompetencyLevel } from '../../../types';

interface ConnectedInternalOccupationOverviewProps {
    className?: string;
}

const ConnectedInternalOccupationOverview: FC<ConnectedInternalOccupationOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const user = useTypedSelector(state => state.userReducer.user);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const competencies = useTypedSelector(state => state.skillsPassportReducer.competencies);
    const skills = useTypedSelector(state => state.skillsPassportReducer.skills);

    const overviewIsLoading = useTypedSelector(state => state.internalOccupationOverviewReducer.isLoading);
    const error = useTypedSelector(state => state.internalOccupationOverviewReducer.error);
    const activeFilterValues = useTypedSelector(state => state.internalOccupationOverviewReducer.activeFilterValues);
    const internalOccupationOverview = useTypedSelector(state => state.internalOccupationOverviewReducer.internalOccupationOverview);
    const pagination = useTypedSelector(state => state.internalOccupationOverviewReducer.pagination);

    const isLoading = [
        overviewIsLoading,
        candidateIsLoading,
        skillsPassportIsLoading,
    ].some(Boolean);

    const handlePaginationChange = (pageNumber: number): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber,
        };

        dispatch(setActiveFilterValues(newFilterValues));

        scrollToTop();
    };

    useEffect((): void => {
        if (!candidate) return;

        const highCompetencies = competencies
            ? competencies[CompetencyLevel.high].map(competency => competency.label)
            : [];

        const regularCompetencies = competencies
            ? competencies[CompetencyLevel.regular].map(competency => competency.label)
            : [];

        if (user && candidate.uuid) {
            const skillLabels = skills.map(skill => skill.name);
            const competencyLabels = [...highCompetencies, ...regularCompetencies];

            dispatch(fetchInternalOccupationOverview(candidate.uuid, user.organisation.id, skillLabels, competencyLabels, activeFilterValues));
        } else {
            dispatch(setInternalOccupationOverviewIsLoading(false));
        }
    }, [candidate, competencies, activeFilterValues]);

    return (
        <OccupationOverview
            isLoading={isLoading}
            occupationIsInternal
            error={error}
            user={user}
            candidate={candidate}
            skills={skills}
            activeFilterValues={activeFilterValues}
            totalAmount={internalOccupationOverview.totalAmount}
            occupations={internalOccupationOverview.occupations}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            className={className}
        />
    );
};

export default ConnectedInternalOccupationOverview;
