import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { SearchableValueInput, SubmitButton } from '../../../compositions';
import trans from '../../../helpers/trans';
import { SearchableOption } from '../../../types';

import './OccupationSearchForm.scss';

interface OccupationSearchFormProps {
    isLoading: boolean;
    suggestionsIsLoading?: boolean;
    value?: string;
    occupationSuggestions?: SearchableOption[];
    onSearchChange?: (value: string) => void;
    onSubmit: (value: string) => void;
    className?: string;
}

const OccupationSearchForm: FC<OccupationSearchFormProps> = ({
    isLoading,
    suggestionsIsLoading,
    value,
    occupationSuggestions = [],
    onSearchChange,
    onSubmit,
    className = '',
}): ReactElement => {
    const [query, setQuery] = useState<string>('');

    const handleQueryChange = (selectedOption: SearchableOption): void => {
        setQuery(selectedOption.label);
        if (onSearchChange) onSearchChange(selectedOption.label);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit(query);
    };

    useEffect((): void => {
        setQuery(value || '');
    }, [value]);

    return (
        <form onSubmit={handleSubmit} className={`occupation-search-form ${className}`}>
            <SearchableValueInput
                isLoading={suggestionsIsLoading}
                isSearchable
                hideIcon
                label={trans('forms.occupationSearch.input.search')}
                value={query}
                options={query.length > 2 ? occupationSuggestions : []}
                placeholder={trans('forms.occupationSearch.placeholder.search')}
                resultLimit={10}
                disabled={isLoading}
                onChange={handleQueryChange}
                className="occupation-search-form__input"
                inputWrapperClassName="occupation-search-form__input-field"
                listClassName="occupation-search-form__input-list"
            />

            <SubmitButton
                isLoading={isLoading}
                icon="arrow-right"
                hideLabel
                text={trans('forms.occupationSearch.submitButtonLabel')}
                className="occupation-search-form__submit-button"
            />
        </form>
    );
};

export default OccupationSearchForm;
