import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { InternalOccupationDetail, InternalOccupationDetailRequest, InternalOccupationDetailResource } from './InternalOccupationDetail';
import { transformToInternalOccupationDetail } from './InternalOccupationDetailTransformers';

export const getInternalOccupationDetailApiCall = async (internalOccupationDetailRequest: InternalOccupationDetailRequest): Promise<FetchResult<InternalOccupationDetail, string>> => {
    try {
        const {
            occupationUuid,
            organisationUuid,
            candidateUuid,
            skillsToMatch,
            competenciesToMatch,
        } = internalOccupationDetailRequest;

        const response = await lloFetch(`/api/internaloccupations/${organisationUuid}/matching/${occupationUuid}`, {
            method: 'POST',
            candidateUuid,
            // TODO: remove the search once the back-end has removed it as well
            body: JSON.stringify({ search: '', skillsToMatch, competenciesToMatch }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const internalOccupationDetailResponse: InternalOccupationDetailResource = await response.json();

        const internalOccupation = transformToInternalOccupationDetail(internalOccupationDetailResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: internalOccupation,
        };
    } catch (error) {
        console.error('[getInternalOccupationDetailApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
