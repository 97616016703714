import { FC, ReactElement } from 'react';

import { Card, CircleProgress } from '../../../components';
import { Notice } from '../../../compositions';
import trans from '../../../helpers/trans';
import { InternalOccupationDetail } from '../../../models/InternalOccupationDetail/InternalOccupationDetail';
import { NoticeType } from '../../../types';
import { InternalOccupationDetailHeaderSkeletons } from './skeletons';

import './InternalOccupationDetailHeader.scss';

interface InternalOccupationDetailHeaderProps {
    pageIsLoading: boolean;
    occupationError: string;
    occupation: InternalOccupationDetail;
    className?: string;
}

const InternalOccupationDetailHeader: FC<InternalOccupationDetailHeaderProps> = ({
    pageIsLoading,
    occupationError,
    occupation,
    className = '',
}): ReactElement => (
    <div className={`internal-occupation-detail-header ${className}`}>
        {pageIsLoading && <InternalOccupationDetailHeaderSkeletons />}

        {!pageIsLoading && occupationError && (
            <Notice
                type={NoticeType.error}
                text={trans('containers.internalOccupationDetail.error')}
            />
        )}

        {!pageIsLoading && !occupationError && (
            <Card className="internal-occupation-detail-header__card">
                <div className="internal-occupation-detail-header__text">
                    <h2 className="internal-occupation-detail-header__name">{occupation.name}</h2>
                    <p className="internal-occupation-detail-header__description">{occupation.description}</p>
                </div>

                <div className="internal-occupation-detail-header__matched-skills">
                    <CircleProgress
                        showPercentage
                        icon="occupation"
                        finishedSteps={occupation.matchedPercentage}
                        className="internal-occupation-detail-header__progress-circle"
                    />
                </div>
            </Card>
        )}
    </div>
);

export default InternalOccupationDetailHeader;
