import { FC, ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Notice, OccupationCard, ViewSelector } from '../../../compositions';
import { getRoleForTranslation } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { OccupationFilterValues } from '../../../models/OccupationOverview/OccupationOverview';
import { Occupation } from '../../../models/Occupations/Occupations';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { Skill } from '../../../models/Skills/Skills';
import { User } from '../../../models/User/User';
import { RoutePaths } from '../../../Routes';
import { NoticeType, ViewOption } from '../../../types';
import { Pagination } from '../..';
import { OccupationOverviewSkeletons } from './skeletons';
import { OccupationOverviewNoSkills, OccupationOverviewNullState } from './subcomponents';

import './OccupationOverview.scss';

export enum OccupationCardView {
    percentage = 'percentage',
    skills = 'skills',
}

interface OccupationOverviewProps {
    isLoading: boolean;
    occupationIsInternal?: boolean;
    showViewOptions?: boolean;
    error: string;
    user?: User;
    candidate?: Candidate;
    skills: Skill[];
    activeFilterValues: OccupationFilterValues;
    totalAmount: number;
    occupations: Occupation[];
    pagination?: PaginationModel;
    onPaginationChange: (currentPage: number) => void;
    className?: string;
}

const OccupationOverview: FC<OccupationOverviewProps> = ({
    isLoading,
    occupationIsInternal,
    showViewOptions,
    error,
    user,
    candidate,
    skills,
    activeFilterValues,
    totalAmount,
    occupations,
    pagination,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const [view, setView] = useState<OccupationCardView>(OccupationCardView.percentage);

    const viewOptions: ViewOption[] = [
        {
            icon: 'percentage',
            label: trans('containers.occupationOverview.view.percentage'),
            value: OccupationCardView.percentage,
        },
        {
            icon: 'numerical',
            label: trans('containers.occupationOverview.view.skills'),
            value: OccupationCardView.skills,
        },
    ];

    const handleViewChange = (newView: string): void => {
        setView(newView as OccupationCardView);
    };

    const handleNoSkillsButtonClick = (): void => {
        navigate(RoutePaths.skillsPassport(candidate?.uuid));
    };

    return (
        <div className={`occupation-overview ${className}`}>
            {isLoading && <OccupationOverviewSkeletons showViewOptions={showViewOptions} />}

            {!isLoading && error && (
                <Notice
                    type={NoticeType.error}
                    text={trans('containers.occupationOverview.error')}
                />
            )}

            {!isLoading && !error && (
                <>
                    <header className="occupation-overview__header">
                        <h2 className="occupation-overview__title">
                            {trans('containers.occupationOverview.title', {
                                totalAmount,
                            })}
                        </h2>

                        {showViewOptions && occupations.length > 0 && (
                            <ViewSelector
                                options={viewOptions}
                                value={view}
                                onChange={handleViewChange}
                                className="occupation-overview__view-selector"
                            />
                        )}
                    </header>

                    {skills.length === 0 && (
                        <OccupationOverviewNoSkills
                            userRoles={user?.roles}
                            nullState={trans(`containers.occupationOverview.noSkills.noResults.${getRoleForTranslation(user?.roles)}`)}
                            callToAction={trans(`containers.occupationOverview.noSkills.callToAction.${getRoleForTranslation(user?.roles)}`)}
                            onButtonClick={handleNoSkillsButtonClick}
                            className="occupation-overview__no-skills"
                        />
                    )}

                    {skills.length > 0 && (
                        <>
                            {occupations.length === 0 && !activeFilterValues.searchQuery && (
                                <OccupationOverviewNullState
                                    description={trans('containers.occupationOverview.nullState.noResults')}
                                    callToAction={trans('containers.occupationOverview.nullState.tryAgainLater')}
                                    className="occupation-overview__null-state"
                                />
                            )}

                            {occupations.length === 0 && activeFilterValues.searchQuery && (
                                <OccupationOverviewNullState
                                    description={trans('containers.occupationOverview.searchNullState.noResults', {
                                        searchQuery: activeFilterValues.searchQuery,
                                    })}
                                    callToAction={trans('containers.occupationOverview.searchNullState.tryAgainLater')}
                                    className="occupation-overview__null-state"
                                />
                            )}

                            {occupations.length > 0 && (
                                <ul className="occupation-overview__list">
                                    {occupations.map(occupation => (
                                        <li key={occupation.id} className="occupation-overview__list-item">
                                            <OccupationCard
                                                key={occupation.id}
                                                occupationIsInternal={occupationIsInternal}
                                                candidateUuid={candidate?.uuid}
                                                view={view}
                                                occupation={occupation}
                                                className="occupation-overview__card"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {pagination && (
                                <Pagination
                                    id="occupation-overview"
                                    amountOfPages={pagination.totalPages}
                                    currentPage={pagination.currentPage}
                                    onChange={onPaginationChange}
                                    className="occupation-overview__pagination"
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default OccupationOverview;
