import { FC, ReactElement, useEffect } from 'react';

import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { sessionLogoutDelay } from './constants';
import { Maintenance } from './containers';
import { isProduction } from './helpers';
import { initTagManager } from './helpers/analytics';
import { initDarkmode } from './helpers/darkmode';
import useTimeout from './hooks/useTimeout';
import RouteComponents, { RoutePaths } from './Routes';

const packageJson = require('../package.json'); // eslint-disable-line

if (process.env.REACT_APP_APP_ENV !== 'local' && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `${packageJson.name}@${packageJson.version}`,
        environment: process.env.REACT_APP_APP_ENV,
        integrations: [new BrowserTracing(), new CaptureConsole({
            levels: ['error'],
        })],
        tracesSampleRate: 1.0,
    });
}

if (process.env.REACT_APP_APP_ENV === 'production') {
    initTagManager({
        originalLocation: window?.location?.href,
    });
}

const App: FC = (): ReactElement | null => {
    const navigate = useNavigate();

    useEffect(initDarkmode, []);

    useTimeout((): void => {
        if (isProduction) {
            navigate(RoutePaths.logout(), {
                state: { sessionEnd: true },
            });
        }
    }, sessionLogoutDelay);

    if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
        return <Maintenance />;
    }

    return (
        <Routes>
            {RouteComponents.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};

export default App;
