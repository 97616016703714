import { isFetchResultSuccessful } from '../../models/FetchResult';
import { InternalOccupationFilterValues } from '../../models/InternalOccupationOverview/InternalOccupationOverview';
import { getInternalOccupationOverviewApiCall } from '../../models/InternalOccupationOverview/InternalOccupationOverviewService';
import { transformToPagination } from '../../models/Pagination';
import { TypedDispatch } from '../store';
import {
    setError,
    setInternalOccupationOverview,
    setIsLoading,
    setPagination,
} from './internalOccupationOverview';

export const setInternalOccupationOverviewIsLoading = (isLoading: boolean) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(isLoading));
};

export const fetchInternalOccupationOverview = (
    candidateUuid: string,
    organisationUuid: string,
    skills: string[],
    competencies: string[],
    activeFilterValues: InternalOccupationFilterValues,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const occupationOverviewResponse = await getInternalOccupationOverviewApiCall(candidateUuid, organisationUuid, skills, competencies, activeFilterValues);

        if (!isFetchResultSuccessful(occupationOverviewResponse)) {
            dispatch(setError(occupationOverviewResponse.error));
            return;
        }

        const occupationOverview = occupationOverviewResponse.data;

        const currentPage = activeFilterValues.pageNumber;
        const pagination = transformToPagination(occupationOverview.totalAmount, currentPage);

        dispatch(setInternalOccupationOverview(occupationOverview));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchInternalOccupationOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
