import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { DevelopmentPlanSkillForm } from '../../../containers';
import { AddDevelopmentPlanSkillFormData } from '../../../models/DevelopmentPlanSkill/DevelopmentPlanSkill';
import { addDevelopmentPlanSkill } from '../../../redux/developmentPlanSkill/developmentPlanSkillActions';
import { fetchSkills } from '../../../redux/skills/skillsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { FormOption } from '../../../types';

interface ConnectedDevelopmentPlanSkillFormProps {
    onCancel: () => void;
    className?: string;
}

const ConnectedDevelopmentPlanSkillForm: FC<ConnectedDevelopmentPlanSkillFormProps> = ({
    onCancel,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const isLoadingCandidateSkillOptions = useTypedSelector(state => state.candidateReducer.isLoading);
    const isLoadingDevelopmentPlanCompetencies = useTypedSelector(state => state.developmentPlanSkillReducer.isLoading);
    const developmentPlan = useTypedSelector(state => state.developmentPlanReducer.developmentPlan);
    const skills = useTypedSelector(state => state.skillsReducer.skills);

    const onSubmitAddSkill = (formData: AddDevelopmentPlanSkillFormData): void => {
        if (candidateUuid) {
            dispatch(addDevelopmentPlanSkill(formData, candidateUuid));
            onCancel();
        }
    };

    useEffectOnce((): void => {
        if (skills.length === 0) {
            dispatch(fetchSkills());
        }
    });

    const skillOptions: FormOption[] = skills.map(skill => ({
        label: skill.name,
        value: skill.escoUuid || skill.id,
    }));

    return (
        <DevelopmentPlanSkillForm
            isLoading={isLoadingDevelopmentPlanCompetencies}
            isLoadingSkills={isLoadingCandidateSkillOptions}
            skillOptions={skillOptions}
            developmentPlanId={developmentPlan?.id || ''}
            onCancel={onCancel}
            onSubmit={onSubmitAddSkill}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanSkillForm;
