import { FC, ReactElement, useState } from 'react';

import { debounceDelay } from '../../../constants';
import { OccupationFilters } from '../../../containers';
import useTimeout from '../../../hooks/useTimeout';
import { setActiveFilterValues } from '../../../redux/occupationOverview/occupationOverview';
import { fetchOccupationSuggestions, setOccupationSuggestionsIsLoading } from '../../../redux/occupationOverview/occupationOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOccupationFiltersProps {
    className?: string;
}

const ConnectedOccupationFilters: FC<ConnectedOccupationFiltersProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const [searchQuery, setSearchQuery] = useState<string>('');

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isLoading = useTypedSelector(state => state.occupationOverviewReducer.isLoading);
    const suggestionsIsLoading = useTypedSelector(state => state.occupationOverviewReducer.suggestionsIsLoading);
    const activeFilterValues = useTypedSelector(state => state.occupationOverviewReducer.activeFilterValues);
    const occupationSuggestions = useTypedSelector(state => state.occupationOverviewReducer.occupationSuggestions);

    const handleSearchChange = (value: string): void => {
        if (candidate?.uuid) {
            dispatch(fetchOccupationSuggestions(candidate.uuid, value));
        }
    };

    const handleSearchSubmit = (value: string): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber: 1,
            searchQuery: value,
        };

        dispatch(setActiveFilterValues(newFilterValues));
    };

    useTimeout((): void => {
        if (searchQuery.length > 2) {
            handleSearchChange(searchQuery);
            dispatch(setOccupationSuggestionsIsLoading(true));
        }
    }, debounceDelay, [searchQuery]);

    return (
        <OccupationFilters
            isLoading={isLoading}
            suggestionsIsLoading={suggestionsIsLoading}
            activeFilterValues={activeFilterValues}
            occupationSuggestions={occupationSuggestions}
            onSearchChange={setSearchQuery}
            onSearchSubmit={handleSearchSubmit}
            className={className}
        />
    );
};

export default ConnectedOccupationFilters;
