import { FC, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TabsNavigation } from '../../../compositions';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { SkillsPassport } from '../../../models/SkillsPassport/SkillsPassport';
import { User } from '../../../models/User/User';
import { RouteParams, RoutePaths } from '../../../Routes';
import { RouteKey, UserRole } from '../../../types';
import { isSkillsAndCompetenciesFinished } from './helpers';
import {
    CoachingAdvice,
    Competencies,
    LearningStyle,
    Personality,
    TalentsAndIncentives,
    Workplace,
} from './tabContainers';

import './SkillsPassportTabs.scss';

export enum SkillsPassportRouteTab {
    default = ':tab',
    personality = 'persoonlijkheid',
    talentsAndIncentives = 'talenten-en-drijfveren',
    competencies = 'competenties',
    learningStyle = 'leerstijl',
    workplace = 'werkomgeving',
    coachingAdvice = 'coachingadviezen',
}

interface SkillsPassportParams extends RouteParams {
    candidateUuid: string;
    tab?: SkillsPassportRouteTab;
}

interface SkillsPassportTabsProps {
    isLoading: boolean;
    user?: User;
    skillsPassport: SkillsPassport;
    onRefresh: () => void;
    className?: string;
}

const SkillsPassportTabs: FC<SkillsPassportTabsProps> = ({
    isLoading,
    user,
    skillsPassport,
    onRefresh,
    className = '',
}) => {
    const navigate = useNavigate();
    const { candidateUuid, tab = SkillsPassportRouteTab.default } = useParams<SkillsPassportParams>();

    const skillsAndCompetenciesIsFinished = isSkillsAndCompetenciesFinished(
        skillsPassport.skills,
        skillsPassport.schoolExperiences,
        skillsPassport.jobExperiences,
    ) && !isLoading;

    const isCandidate = userHasRole(UserRole.candidate, user?.roles);

    const tabs = [
        {
            key: RouteKey.personality,
            to: RoutePaths.skillsPassportTab(candidateUuid, SkillsPassportRouteTab.personality),
            label: trans('pages.skillsPassport.tabs.personality'),
        },
        {
            key: RouteKey.talentsAndIncentives,
            to: RoutePaths.skillsPassportTab(candidateUuid, SkillsPassportRouteTab.talentsAndIncentives),
            label: trans('pages.skillsPassport.tabs.talentsAndIncentives'),
        },
        {
            key: RouteKey.competencies,
            to: RoutePaths.skillsPassportTab(candidateUuid, SkillsPassportRouteTab.competencies),
            label: trans('pages.skillsPassport.tabs.skillsAndCompetencies'),
            hasIndicator: !skillsAndCompetenciesIsFinished && !isLoading,
        },
        {
            key: RouteKey.learningStyle,
            to: RoutePaths.skillsPassportTab(candidateUuid, SkillsPassportRouteTab.learningStyle),
            label: trans('pages.skillsPassport.tabs.learningStyle'),
        },
        {
            key: RouteKey.workplace,
            to: RoutePaths.skillsPassportTab(candidateUuid, SkillsPassportRouteTab.workplace),
            label: trans('pages.skillsPassport.tabs.workplace'),
        },
        ...(!isCandidate ? [
            {
                key: RouteKey.coachingAdvice,
                to: RoutePaths.skillsPassportTab(candidateUuid, SkillsPassportRouteTab.coachingAdvice),
                label: trans('pages.skillsPassport.tabs.coachingAdvice'),
            },
        ] : []),
    ];

    const navigateToTab = (to: SkillsPassportRouteTab): void => (
        navigate(RoutePaths.skillsPassportTab(candidateUuid, to), { replace: true })
    );

    useEffect((): void => {
        if (tab === SkillsPassportRouteTab.default) {
            if (userHasRole(UserRole.candidate, user?.roles) && !skillsAndCompetenciesIsFinished) {
                navigateToTab(SkillsPassportRouteTab.competencies);
            } else {
                navigateToTab(SkillsPassportRouteTab.personality);
            }
        }
    }, [tab]);

    return (
        <div className={`skills-passport-tabs ${className}`}>
            <TabsNavigation
                navigationLabel={trans('containers.skillsPassportTabs.label')}
                navigationItems={tabs}
                className="skills-passport-tabs__tabs-navigation"
            />

            <div className="skills-passport-tabs__tab">
                {tab === SkillsPassportRouteTab.personality && (
                    <Personality onRefresh={onRefresh} />
                )}

                {tab === SkillsPassportRouteTab.talentsAndIncentives && (
                    <TalentsAndIncentives userRoles={user?.roles} onRefresh={onRefresh} />
                )}

                {tab === SkillsPassportRouteTab.competencies && (
                    <Competencies onRefresh={onRefresh} />
                )}

                {tab === SkillsPassportRouteTab.learningStyle && (
                    <LearningStyle onRefresh={onRefresh} />
                )}

                {tab === SkillsPassportRouteTab.workplace && (
                    <Workplace onRefresh={onRefresh} />
                )}

                {(tab === SkillsPassportRouteTab.coachingAdvice && !isCandidate) && (
                    <CoachingAdvice />
                )}
            </div>
        </div>
    );
};

export default SkillsPassportTabs;
